import { useContext } from "react";
import EditIcon from "@mui/icons-material/Edit";
import PreviewIcon from "@mui/icons-material/Preview";
import PublishIcon from "@mui/icons-material/Publish";
import { useDisclosure } from "@nextui-org/react";
import PropTypes from "prop-types";

import { SubmissionModal, usePreviewSubmission, useSubmission } from "features/submission";
import { AddUserInfoModal, UserContext } from "features/user";

import { Button, ButtonGroup, ConfirmationModal } from "ui";

import EnterBriefFormOptions from "./EnterBriefFormOptions";

export default function EnterBriefFormActions({
  briefId,
  handleSubmit,
  handleError,
  handleCreateOrUpdateSubmission,
  setPublished,
  getValues,
  submissionId = null,
  isLoading = false,
}) {
  const { data: submission } = useSubmission(submissionId);
  const { user } = useContext(UserContext);
  const {
    isOpen: isUserInfoModalOpen,
    onOpen: openUserInfoModal,
    onClose: closeUserInfoModal,
  } = useDisclosure();
  const {
    isOpen: isPublishConfirmOpen,
    onOpen: openPublishConfirm,
    onClose: closePublishConfirm,
  } = useDisclosure();
  const {
    submission: previewSubmission,
    handleClearPreviewSubmission,
    handleCreatePreviewSubmission,
  } = usePreviewSubmission({ briefId, getValues });

  const handlePublishSubmission = (formData) => {
    if (!(user.dob && user.country_code && user.gender)) {
      openUserInfoModal();
      return {};
    }

    return handleCreateOrUpdateSubmission(formData);
  };

  const handleSaveDraft = (e) => {
    if (submission.published) return;

    setPublished(false);
    handleSubmit(handleCreateOrUpdateSubmission, handleError)(e);
  };

  const handlePublish = (e, skipUserInfoModal = false) => {
    setPublished(true);
    handleSubmit(
      skipUserInfoModal ? handleCreateOrUpdateSubmission : handlePublishSubmission,
      handleError,
    )(e);
  };

  return (
    <>
      <ButtonGroup className="flex items-center justify-end gap-1">
        <Button
          size="lg"
          color="success"
          trackingName="preview submission"
          trackingLocation="buttons"
          startContent={<PreviewIcon />}
          onClick={handleCreatePreviewSubmission}
        >
          Preview
        </Button>

        {!submission?.published ? (
          <>
            <Button
              size="lg"
              color="primary"
              data-testid="enter-brief-draft"
              isLoading={isLoading}
              aria-label="draft"
              trackingName="save draft"
              trackingLocation="buttons"
              startContent={<EditIcon />}
              onClick={handleSaveDraft}
            >
              Save Draft
            </Button>

            <Button
              size="lg"
              color="primary"
              isLoading={isLoading}
              data-testid="enter-brief-submit"
              trackingName="publish submission"
              trackingLocation="buttons"
              aria-label="publish"
              startContent={<PublishIcon />}
              onClick={openPublishConfirm}
            >
              Publish
            </Button>
          </>
        ) : (
          <Button
            color="primary"
            size="lg"
            data-testid="enter-brief-submit"
            aria-label="update"
            isLoading={isLoading}
            trackingName="save changes"
            trackingLocation="buttons"
            onClick={handlePublish}
          >
            Save Changes
          </Button>
        )}
      </ButtonGroup>

      <EnterBriefFormOptions
        submissionId={submissionId}
        handlePublish={submission.published ? handlePublish : openPublishConfirm}
        handleSaveDraft={handleSaveDraft}
        handlePreview={handleCreatePreviewSubmission}
      />

      <AddUserInfoModal
        title="Just one more step!"
        show={isUserInfoModalOpen}
        onSuccess={() => {
          handlePublish(new Event(""), true);
          closeUserInfoModal();
        }}
        onHide={closeUserInfoModal}
        footerProps={{
          confirmText: "Finish!",
          cancelText: "skip",
          onCancel: (e) => {
            handlePublish(e, true);
            closeUserInfoModal();
          },
        }}
      />

      <ConfirmationModal
        show={isPublishConfirmOpen}
        onHide={closePublishConfirm}
        action={handlePublish}
        confirmText="Publish"
        promptText="Are you sure want to publish this submission? Published submissions will be entered into the brief."
      />

      <SubmissionModal
        show={Object.keys(previewSubmission).length > 0}
        onHide={handleClearPreviewSubmission}
        submission={previewSubmission}
      />
    </>
  );
}
EnterBriefFormActions.propTypes = {
  briefId: PropTypes.number.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleError: PropTypes.func.isRequired,
  handleCreateOrUpdateSubmission: PropTypes.func.isRequired,
  setPublished: PropTypes.func.isRequired,
  getValues: PropTypes.func.isRequired,
  submissionId: PropTypes.number,
  isLoading: PropTypes.bool,
};
