import { useMemo, useRef, useState } from "react";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import { motion } from "framer-motion";
import PropTypes from "prop-types";
import {
  generatePath,
  Link,
  Navigate,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { twMerge } from "tailwind-merge";

import { useProtectedAction } from "features/authentication";
import { BriefStatus, briefStatusContent, useBrief, useEnterBriefMutations } from "features/brief";
import { PageLayout } from "features/layout";
import { ErrorToast, NotFound } from "features/report";
import { ShareSubmissionModal } from "features/submission";

import { handleFormError } from "forms";
import { Button, Col, Container, Image, Row, ScrollToTopButton, Tab, Tabs, Zoom } from "ui";

import useScroll from "hooks/useScroll";
import useWindowSize from "hooks/useWindowSize";

import routes from "default/routes";

import BriefDetails from "./BriefDetails";
import BriefPageCard from "./BriefPageCard";
import BriefSubmissions from "./BriefSubmissions";
import BriefTimeline from "./BriefTimeline";
import BriefWorkspace from "./BriefWorkspace";

const BREAKPOINT = 768;

function BriefCTAButton({ briefId, ...buttonProps }) {
  const { data: brief } = useBrief(briefId);

  const { callToAction } = briefStatusContent(brief)[brief.status] ?? {};
  if (!callToAction) return null;

  const {
    enterBrief: { mutate, isPending: enterBriefLoading },
  } = useEnterBriefMutations();
  const navigate = useNavigate();

  const [handleCreateSubmission] = useProtectedAction(
    (briefUrlId) => {
      mutate(
        { id: briefUrlId },
        {
          onSuccess: () => {
            navigate(callToAction.path);
          },
          onError: (error, errorData) =>
            handleFormError({
              error,
              data: errorData,
              errorPage: "enter_brief",
              toast: ErrorToast,
            }),
        },
      );
    },
    {
      actionName: "enterBrief",
      redirectToPath: generatePath(routes.brief, { id: briefId }),
    },
  );

  return (
    <Button
      color="primary"
      size="lg"
      as={Link}
      to={callToAction.path}
      trackingName={callToAction.trackingName}
      data-testid="brief-cta"
      isLoading={enterBriefLoading}
      onClick={(e) => {
        // if completed, dont click and enter brief
        if (brief.status === "completed" && !brief.entered) {
          e.preventDefault();
          handleCreateSubmission(briefId);
        }
      }}
      {...buttonProps}
    >
      {callToAction.text}
    </Button>
  );
}
BriefCTAButton.propTypes = {
  briefId: PropTypes.number.isRequired,
};

export default function Brief() {
  const { id } = useParams();
  const { state } = useLocation();
  const [finalPage, setFinalPage] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const scrollRef = useRef(null);
  const { atEnd, atStart, scrollRight, scrollLeft } = useScroll(scrollRef);
  const { data, isLoading } = useBrief(id);
  const [width] = useWindowSize();
  const navigate = useNavigate();
  const brief = data ?? {};
  const moodboardFiles = brief.files?.filter(
    (f) =>
      f.file.name.endsWith(".png") ||
      f.file.name.endsWith(".jpeg") ||
      f.file.name.endsWith(".webp"),
  );
  const showTabs =
    brief.user_submission_count > 0 || // show workspace tab
    (["voting", "judging", "completed"].includes(brief.status) &&
      brief.total_submission_count > 0) ||
    moodboardFiles?.length > 0; // show moodboard tab

  const defaultTab = useMemo(() => {
    if (searchParams.get("tab")) return searchParams.get("tab");
    if (brief.id === null) return "details";
    if (
      ["submitting", "proposing", "selecting"].includes(brief.status) &&
      brief.user_submission_count > 0
    )
      return "workspace";
    if (["voting", "judging"].includes(brief.status) && brief.total_submission_count > 0)
      return "submissions";
    return "details";
  }, [brief]);

  if (!isLoading && Object.keys(brief).length <= 0) {
    return (
      <NotFound
        text={"Bummer. \n The requested brief could not be found or does not exist."}
        issuePage="enter_brief"
      />
    );
  }

  if (
    process.env.REACT_APP_ENABLE_BRIEF_LANDING === "true" &&
    Object.keys(brief).length > 0 &&
    !brief?.entered &&
    ["scheduled", "submitting", "proposing", "selecting"].includes(brief.status)
  )
    return <Navigate to={generatePath(routes.briefLanding, { id })} replace />;

  return (
    <PageLayout
      isLoading={isLoading}
      pageName="Brief"
      meta={{
        image: brief.banner?.url || brief.thumbnail,
        description: `Oditi Brief: ${brief.title}`,
      }}
      showFooter={finalPage}
      navbarProps={{
        size: "lg",
      }}
    >
      {Object.keys(brief).length > 0 && (
        <>
          <Container className="after:pb-12 after:block mb-8 contain-paint">
            <BriefPageCard
              briefId={id}
              classNames={{
                base: "xl:mb-5 [@media(max-height:1025px)]:hidden",
                body: "p-10",
                footer: "bottom-10 w-[calc(100%_-_80px)] ms-10",
              }}
            />

            <Row gap={3} className="gap-y-5 mb-20 md:mb-0">
              <Col xs={12} xl={4}>
                <div className="initial lg:sticky top-[88px] flex flex-col gap-5">
                  <BriefPageCard
                    briefId={id}
                    classNames={{
                      base: "[@media(min-height:1025px)]:hidden",
                      title: "text-4xl",
                    }}
                  />
                  <div className="hidden xl:flex p-4 bg-content2 rounded-3xl relative flex flex-col gap-4">
                    <span className="text-default-400 uppercase mt-1">
                      Are you up for the challenge?
                    </span>

                    {brief.challenge_statement && (
                      <p className="mb-0">{brief.challenge_statement}</p>
                    )}

                    <BriefCTAButton briefId={id} fullWidth />
                  </div>
                  <BriefTimeline briefId={id} className="hidden xl:block" />
                </div>
              </Col>

              <Col xs={12} xl={8}>
                <div className="relative">
                  <Tabs
                    ref={scrollRef}
                    fullWidth
                    size="lg"
                    radius="lg"
                    color="primary"
                    classNames={{
                      base: twMerge(
                        "max-w-full flex justify-center pb-5",
                        !showTabs && "hidden pb-0",
                      ),
                      tab: "uppercase sm:h-auto sm:py-2",
                      cursor: "rounded-2xl",
                      tabList: "p-2",
                      tabContent: twMerge("p-1", !showTabs && "hidden"),
                    }}
                    onSelectionChange={(tab) => {
                      setSearchParams({ tab });
                      setFinalPage(true);
                    }}
                    defaultSelectedKey={defaultTab}
                  >
                    <Tab key="details" title="Overview" className="py-0">
                      <BriefDetails briefId={id} />
                    </Tab>

                    {brief.total_submission_count > 0 &&
                      (["voting", "judging", "completed"].includes(brief.status) ||
                        brief.type === "challenge") && (
                        <Tab key="submissions" title="Submissions" className="py-0">
                          <BriefSubmissions briefId={id} setFinalPage={setFinalPage} />
                        </Tab>
                      )}

                    {(brief.user_submission_count > 0 || state?.postSubmission) && (
                      <Tab key="workspace" title="Workspace" className="py-0">
                        <BriefWorkspace briefId={id} />
                      </Tab>
                    )}

                    {moodboardFiles?.length > 0 && (
                      <Tab key="moodboard" title="Moodboard" className="py-0">
                        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                          {moodboardFiles.map((reference) => (
                            <Zoom key={reference.file.url}>
                              <Image src={reference.file.url} />
                            </Zoom>
                          ))}
                        </div>
                      </Tab>
                    )}
                  </Tabs>

                  <Button
                    isIconOnly
                    size="sm"
                    color="primary"
                    variant="light"
                    className={`absolute h-11 top-1 left-2 backdrop-blur-sm touch-device:invisible ${atStart && "hidden"}`}
                    onClick={() => scrollLeft()}
                  >
                    <ChevronLeft color="inherit" />
                  </Button>
                  <Button
                    isIconOnly
                    size="sm"
                    color="primary"
                    variant="light"
                    className={`absolute h-11 top-1 right-2 backdrop-blur-sm touch-device:invisible ${atEnd && "hidden"}`}
                    onClick={() => scrollRight()}
                  >
                    <ChevronRight />
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>

          <motion.div
            className="xl:hidden z-10 fixed bottom-0 left-0 w-full"
            initial={{ y: "5rem" }}
            exit={{ y: "5rem" }}
            animate={{
              y: width < BREAKPOINT ? "-4rem" : 0,
            }}
            transition={{
              duration: 0.2,
            }}
          >
            <div className="bg-content1 shadow-2xl w-full rounded-t-3xl py-3 px-3 md:px-8 flex z-20 items-center">
              <BriefStatus
                briefId={id}
                showHelpButton={false}
                classNames={{
                  base: twMerge(
                    "justify-center hidden md:flex items-center",
                    ["voting", "judging"].includes(brief.status) && "visible !flex",
                  ),
                  wrapper:
                    ["voting", "judging"].includes(brief.status) && "flex gap-4 items-center",
                }}
              />

              <BriefCTAButton briefId={id} fullWidth />
            </div>
          </motion.div>

          <ShareSubmissionModal
            show={state?.postSubmission}
            shareUrl={state?.shareUrl}
            votes={state?.votes}
            onHide={() => navigate(window.location.pathname, { replace: true, state: null })}
            postSubmission
          />
        </>
      )}

      <ScrollToTopButton />
    </PageLayout>
  );
}
