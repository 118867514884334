import { motion } from "framer-motion";
import PropTypes from "prop-types";
import { addMethod, boolean, mixed, object, string } from "yup";

import { useBrief } from "features/brief";
import { ErrorToast } from "features/report";
import { useSubmission, useSubmissionMutations } from "features/submission";

import {
  ControlledMultiFileInput,
  ControlledMultiImageInput,
  ControlledTextarea,
  ControlledThumbnailInput,
  Controller,
  Form,
  handleFormError,
  Input,
  useForm,
  yupResolver,
} from "forms";
import { Spinner } from "ui";

import {
  deserializeFileData,
  deserializeImageData,
  serializeExistingFileData,
  serializeNewFileData,
} from "hooks/useGcsFileUpload";
import { stringifyFormData } from "utils/helpers";
import {
  validateFileRequired,
  validateFileSize,
  validateFileTypes,
} from "utils/validation/validationMethods";

import EnterBriefFormActions from "./EnterBriefFormActions";
import { enterBriefPolicies } from "./policyData";

addMethod(mixed, "fileSize", validateFileSize);
addMethod(mixed, "fileTypes", validateFileTypes);
addMethod(mixed, "requiredFile", validateFileRequired);

const schema = object().shape({
  published: boolean(),
  require_working: boolean(),
  title: string().when("published", {
    is: true,
    then: string().required(),
    otherwise: string(),
  }),
  description: string().when("published", {
    is: true,
    then: string().required(),
    otherwise: string(),
  }),
  thumbnail: mixed().when("published", {
    is: true,
    then: mixed().fileTypes().fileSize(26214400).requiredFile(),
    otherwise: mixed().fileTypes().fileSize(26214400),
  }),
  images: mixed().when("published", {
    is: true,
    then: mixed().fileTypes().fileSize(26214400).requiredFile(),
    otherwise: mixed().fileTypes().fileSize(26214400),
  }),
  technical_file: mixed().when(["published", "require_working"], {
    is: true,
    then: mixed().requiredFile("Technical file is a required field"),
    otherwise: mixed(),
  }),
});
const ORDER = ["thumbnail", "title", "description", "images", "technical_file"];

export default function EnterBriefForm({
  briefId,
  submissionId = null,
  onPublish = () => {},
  onSave = () => {},
}) {
  const { data: brief } = useBrief(briefId);
  const { data: submission } = useSubmission(submissionId);
  const { createSubmission, updateSubmission } = useSubmissionMutations();
  const {
    getValues,
    control,
    handleSubmit,
    setError,
    setValue,
    setFocus,
    formState: { errors },
  } = useForm({
    shouldFocusError: false,
    resolver: yupResolver(schema),
    values: {
      thumbnail: submission.thumbnail ?? "",
      title: submission.title ?? "",
      description: submission.description ?? "",
      published: submission.published ?? false,
      require_working: brief.require_working,
      images: submission.images
        ? deserializeImageData(submission.images.sort((a, b) => a.order_id - b.order_id))
        : [],
      technical_file: submission.files
        ? deserializeFileData(submission.files).filter((f) => f.type === "technical")
        : [],
    },
  });
  const loading = createSubmission.isPending || updateSubmission.isPending;

  const serializeFormData = (formData) => {
    formData.new_files = serializeNewFileData(
      formData.images
        .filter((f) => f.isNewFile)
        .concat(formData.technical_file?.filter((f) => f.isNewFile) ?? []),
    );
    formData.existing_files = serializeExistingFileData(
      formData.images
        .filter((f) => !f.isNewFile)
        .concat(formData.technical_file?.filter((f) => !f.isNewFile) ?? []),
    );
    formData.type = brief.status === "proposing" ? "proposal" : "submission";
    if (!(formData.thumbnail instanceof File)) delete formData.thumbnail;

    delete formData.require_working;
    delete formData.images;
    delete formData.technical_file;

    return formData;
  };

  const handleCreateOrUpdateSubmission = (formData) => {
    const serializedFormData = serializeFormData({ ...formData }); // Copy so we dont change values of hook form data.
    let data = stringifyFormData(serializedFormData, ["thumbnail"]);
    data = submission.id ? { id: submission.id, data } : { briefId: brief.id, data };
    const apiCall = submission.id ? updateSubmission : createSubmission;

    return apiCall.mutate(data, {
      onSuccess: ({ data: submissionData }) => {
        if (formData.published && !submission.published) onPublish(submissionData);
        else onSave(submissionData);
      },
      onError: (error) => {
        handleFormError({
          error,
          setError,
          data: serializedFormData,
          errorPage: "enter_brief",
          toast: ErrorToast,
        });
      },
    });
  };

  return (
    <>
      <Form className="gap-8" validated={false} noValidate autoComplete="off">
        <div className="bg-default-50 px-4 p-6 sm:p-8 rounded-3xl">
          <ControlledThumbnailInput
            control={control}
            name="thumbnail"
            label="Add submission thumbnail"
            isInvalid={!!errors.thumbnail}
            errorMessage={errors.thumbnail?.message}
          />
        </div>

        <div className="bg-default-50 px-4 p-6 sm:p-8 rounded-3xl flex flex-col gap-5">
          <Controller
            control={control}
            name="title"
            render={({ field: { value, ...field } }) => (
              <Input
                {...field}
                id="title"
                defaultValue={value}
                label="Submission Title"
                isRequired
                maxLength={50}
                description={`${value?.length}/50`}
                classNames={{
                  inputWrapper: "border-foreground border-1",
                  description: "text-right font-local",
                }}
                isInvalid={!!errors.title}
                errorMessage={errors.title?.message}
              />
            )}
          />

          <ControlledTextarea
            control={control}
            name="description"
            id="description"
            label="Submission Description"
            placeholder="Tell the world about your idea. Describe your work and tell everyone about your inspiration too."
            maxLength={1000}
            minRows={10}
            isRequired
            classNames={{
              inputWrapper: "border-foreground border-1",
              description: "text-right font-local",
            }}
            isInvalid={!!errors.description}
            errorMessage={errors.description?.message}
          />
        </div>

        <div className="bg-default-50 px-4 p-6 sm:p-8 rounded-3xl">
          <ControlledMultiImageInput
            control={control}
            name="images"
            label="Submission Images (max 10)"
            fileErrors={errors}
            isInvalid={!!errors.images}
            errorMessage={errors.images?.message}
          />
        </div>

        <div className="bg-default-50 px-4 p-6 sm:p-8 rounded-3xl">
          <ControlledMultiFileInput
            label="Upload Working File (.AI/.PSD/.TIFF/.EPS/.OBJ)"
            name="technical_file"
            control={control}
            fileType="technical"
            maxSize={null}
            accept={{}}
            fileErrors={errors}
            isInvalid={!!errors.technical_file}
            errorMessage={errors.technical_file?.message}
            isRequired={getValues("technical_file")?.length > 0 ? true : brief.require_working}
          />
        </div>

        <div
          className="bg-default-50 px-4 p-6 sm:p-8 rounded-3xl"
          data-testid="enter-brief-policies"
        >
          <h6 className="uppercase font-bold">
            By Continuing you are agreeing to the following terms:
          </h6>
          <ul>
            {enterBriefPolicies.map((policy) => {
              const agreement = policy.agreement(brief);
              if (!agreement) return null;

              return (
                <li key={policy.name}>
                  <small className="font-roman">{agreement}</small>
                </li>
              );
            })}
          </ul>
        </div>

        <EnterBriefFormActions
          isLoading={loading}
          setPublished={(v) => setValue("published", v)}
          handleError={(err) => setFocus(ORDER.find((e) => Object.keys(err).includes(e)))}
          {...{
            handleSubmit,
            handleCreateOrUpdateSubmission,
            submissionId,
            briefId,
            getValues,
          }}
        />
      </Form>

      {loading && (
        <motion.div
          initial={{ opacity: 0 }}
          exit={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.2 }}
          className="bg-primary/20 h-full w-full -top-0 left-0 fixed z-50 flex items-center justify-center"
        >
          <Spinner size="lg" label="Saving..." />
        </motion.div>
      )}
    </>
  );
}
EnterBriefForm.propTypes = {
  briefId: PropTypes.number.isRequired,
  submissionId: PropTypes.number,
  onPublish: PropTypes.func,
  onSave: PropTypes.func,
};
